<template>
  <EuiContainer>
    <EuiGrid gutters>
      <EuiGridRow>
        <EuiGridColumn>
          <div class="flex items-center">
            <EuiHeading :level="1" bold>{{ $t('signatories.external.index.title') }}</EuiHeading>
            <div class="flex flex-1 justify-end">
              <EuiButton variant="raised" color="primary" @click="$router.push({ name: 'signatories.external.create' })">{{ $t('signatories.external.index.create') }}</EuiButton>
            </div>
          </div>
        </EuiGridColumn>
      </EuiGridRow>
      <EuiGridRow>
        <EuiGridColumn>
          <EuiBlock :title="$i18n.t('signatories.external.index.block.title')">
              <EuiGrid>
                <EuiGridRow>
                  <EuiGridColumn>
                    <div v-if="selectedSignatories.length > 0">
                    <span class="font-bold">{{ $tc('signatories.external.selected', selectedSignatories.length) }}</span>
                    <EuiButton variant="text" color="primary" @click="deleteSignatories">{{$t('button.delete')}}</EuiButton>
                    </div>
                  </EuiGridColumn>
                  <EuiGridColumn width="1/3">
                    <EuiInput class="eui-m-cardFilter search" :placeholder="$t('searchBar.placeholder')"
                              v-model.trim="searchInput"
                              @keyup.enter="searchByName()">
                      <template #endEnhancer>
                        <div class="eui-m-cardFilter__clear">
                          <template v-if="searchInput && searchInput.length > 0">
                            <EuiButton iconOnly size="small" color="primary" @click.stop="onClear">
                              <EuiIcon name="close"/>
                            </EuiButton>
                            <div class="eui-m-cardFilter__separator"></div>
                          </template>
                        </div>
                        <EuiButton iconOnly color="primary" size="small" @click.stop="searchByName()">
                          <EuiIcon name="search"/>
                        </EuiButton>
                      </template>
                    </EuiInput>
                  </EuiGridColumn>
                </EuiGridRow>
              </EuiGrid>
              <div v-show="this.filters.length > 0">
                <EuiDivider secondary class="m-1"/>
                <div class="eui-u-flex eui-u-items-center eui-u-ml-6">
                  <EuiHeading class='eui-u-text-blue-grey eui-u-my-2' :level="3" bold>{{ $t('filterBar.title')}}</EuiHeading>
                  <EuiIcon class='eui-u-text-blue-grey eui-u-ml-2' name='Filter2' size='medium'/>
                  <EuiChip v-for="filter in filters"
                           closable
                           @click:close="removeFilter(filter)"
                           :key="filter.name"
                           class="eui-u-text-white eui-u-ml-4">
                    <template #text>
                      <span class="eui-u-font-bold eui-u-text-white">{{ $t(`filterBar.chips.title.${filter.name}`) }}:</span>
                      <span class="eui-u-text-white">{{ filter.value }}</span>
                    </template>
                  </EuiChip>
                  <EuiChip :text="$t('filterBar.button.reset')"
                           clickable outlined
                           class="eui-u-ml-4"
                           @click:chip="resetFilters"/>
                </div>
                <EuiDivider secondary class="m-1"/>
              </div>
              <EuiTable v-if="signatories.length > 0">
                <EuiTableHeader>
                  <EuiTableRow>
                    <!-- checkbox -->
                    <EuiTableHead dataType="input" class="pt-0 pb-0">
                      <EuiCheckbox v-model="selectAll"/>
                    </EuiTableHead>
                    <!-- full name -->
                    <EuiTableHead class="pt-0 pb-0">
                      {{ $t('signatories.external.index.table.name') }}
                      <EuiDropdownMenu ref="ddNameFilter" :closeOnClickInside="false">
                        <EuiCardFilter :searchPlaceholder="$t('searchBar.placeholder')"
                                       :buttonText="$t('cardFilter.button.filter')"
                                       v-model="availableFilters.name.searchFieldValue"
                                       @click:search="filterOnColumn(availableFilters.name, availableFilters.name.searchFieldValue); $refs.ddNameFilter.doClose()"
                                       @enter:searchBar="filterOnColumn(availableFilters.name, availableFilters.name.searchFieldValue); $refs.ddNameFilter.doClose()"
                                       @click:filter="filterOnColumn(availableFilters.name, availableFilters.name.searchFieldValue); $refs.ddNameFilter.doClose()">
                        </EuiCardFilter>
                        <template #anchor><EuiButton iconOnly><EuiIcon name="more_vert" color="primary"/></EuiButton></template>
                      </EuiDropdownMenu>
                    </EuiTableHead>

                    <EuiTableHead class="pt-0 pb-0">
                      {{ $t('signatories.external.index.table.email') }}
                      <EuiDropdownMenu ref="ddEmailFilter" :closeOnClickInside="false">
                        <EuiCardFilter :searchPlaceholder="$t('searchBar.placeholder')"
                                       :buttonText="$t('cardFilter.button.filter')"
                                       v-model="availableFilters.email.searchFieldValue"
                                       @click:search="filterOnColumn(availableFilters.email, availableFilters.email.searchFieldValue); $refs.ddEmailFilter.doClose()"
                                       @enter:searchBar="filterOnColumn(availableFilters.email, availableFilters.email.searchFieldValue); $refs.ddEmailFilter.doClose()"
                                       @click:filter="filterOnColumn(availableFilters.email, availableFilters.email.searchFieldValue); $refs.ddEmailFilter.doClose()">
                        </EuiCardFilter>
                        <template #anchor>
                          <EuiButton iconOnly>
                            <EuiIcon name="more_vert" color="primary"/>
                          </EuiButton>
                        </template>
                      </EuiDropdownMenu>
                    </EuiTableHead>
                    <!--<EuiTableHead showFilterSort @onSortClick="onSortClick($event, 'org')" :main=true>{{ $t('signatories.external.index.table.organization') }}</EuiTableHead>-->

                    <EuiTableHead class="pt-0 pb-0" v-if="showService">
                        {{ $t('signatories.external.index.table.service') }}
                        <EuiDropdownMenu ref="ddServiceFilter" :closeOnClickInside="false">
                          <EuiCardFilter :searchPlaceholder="$t('searchBar.placeholder')"
                                         :buttonText="$t('cardFilter.button.filter')"
                                         v-model="availableFilters.service.searchFieldValue"
                                         @click:search="filterOnColumn(availableFilters.service, availableFilters.service.searchFieldValue); $refs.ddServiceFilter.doClose()"
                                         @enter:searchBar="filterOnColumn(availableFilters.service, availableFilters.service.searchFieldValue); $refs.ddServiceFilter.doClose()"
                                         @click:filter="filterOnColumn(availableFilters.service, availableFilters.service.searchFieldValue); $refs.ddServiceFilter.doClose()">
                          </EuiCardFilter>
                          <template #anchor>
                            <EuiButton iconOnly>
                              <EuiIcon name="more_vert"/>
                            </EuiButton>
                          </template>
                        </EuiDropdownMenu>
                    </EuiTableHead>
                    <EuiTableHead class="pt-0 pb-0" v-if="showPhoneNumber">
                        {{ $t('signatories.external.index.table.phoneNumber') }}
                        <EuiDropdownMenu ref="ddPhoneNumberFilter" :closeOnClickInside="false">
                          <EuiCardFilter :searchPlaceholder="$t('searchBar.placeholder')"
                                         :buttonText="$t('cardFilter.button.filter')"
                                         v-model="availableFilters.phoneNumber.searchFieldValue"
                                         @click:search="filterOnColumn(availableFilters.phoneNumber, availableFilters.phoneNumber.searchFieldValue); $refs.ddPhoneNumberFilter.doClose()"
                                         @enter:searchBar="filterOnColumn(availableFilters.phoneNumber, availableFilters.phoneNumber.searchFieldValue); $refs.ddPhoneNumberFilter.doClose()"
                                         @click:filter="filterOnColumn(availableFilters.phoneNumber, availableFilters.phoneNumber.searchFieldValue); $refs.ddPhoneNumberFilter.doClose()">
                          </EuiCardFilter>
                          <template #anchor>
                            <EuiButton iconOnly>
                              <EuiIcon name="more_vert" color="primary"/>
                            </EuiButton>
                          </template>
                        </EuiDropdownMenu>
                    </EuiTableHead>

                    <EuiTableHead dataType="button"/>
                  </EuiTableRow>
                </EuiTableHeader>
                <EuiTableBody>
                  <!-- table row -->
                  <EuiTableRow v-for="item in signatories" :key="item.id" >
                    <EuiTableCell dataType="input">
                      <EuiCheckbox :value="item" v-model="selectedSignatories"/>
                    </EuiTableCell>
                    <!-- full name -->
                    <EuiTableCell>{{ item.fullname }}</EuiTableCell>
                    <EuiTableCell>{{ item.email }}</EuiTableCell>
                    <EuiTableCell v-if="showService">{{ item.service }}</EuiTableCell>
                    <EuiTableCell v-if="showPhoneNumber">{{ item.mobilePhone }}</EuiTableCell>
                    <!--<EuiTableCell :main=true>{{ item }}</EuiTableCell>-->
                    <EuiTableCell dataType="button">
                      <EuiDropdownMenu>
                        <template v-slot:anchor>
                          <EuiButton iconOnly color="primary" size="small">
                            <EuiIcon name="more_vert"/>
                          </EuiButton>
                        </template>
                        <EuiList>
                          <EuiListItem :button="true" @click="toSignatoryEdit(item.userId)">{{ $t('button.edit') }}</EuiListItem>
                          <EuiListItem :button="true" @click="deleteSignatory(item)">
                            {{ $t('button.delete') }}
                          </EuiListItem>
                        </EuiList>
                      </EuiDropdownMenu>
                    </EuiTableCell>
                  </EuiTableRow>
                </EuiTableBody>
              </EuiTable>
              <EuiPagination v-if="signatories.length"
                             class="mt-4"
                             :value="pagination.current"
                             @change="goToPage"
                             :paginationText="$t('pagination.text')"
                             :totalPages="pagination.total"/>
            <div v-if="signatories.length === 0">
              <p>{{ $t('signatories.external.index.noSignatories') }}</p>
            </div>
          </EuiBlock>
        </EuiGridColumn>
      </EuiGridRow>
    </EuiGrid>
  </EuiContainer>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import Axios from 'axios';

export default {
  name: 'SignatoriesExternalIndex',
  data() {
    return {
      selectedSignatories: [],
      searchInput: '',
      searchParams: {
        orders: [{ fieldName: 'fullname', orderBy: 'ASC' }],
        params: [],
        offset: 0,
        limit: 20,
      },
      availableFilters: {
        name: {
          type: 'text', name: 'name', value: '', searchParamName: 'token', searchFieldValue: '',
        },
        email: {
          type: 'text', name: 'email', value: '', searchParamName: 'email', searchFieldValue: '',
        },
        service: {
          type: 'text', name: 'service', value: '', searchParamName: 'service', searchFieldValue: '',
        },
        phoneNumber: {
          type: 'text', name: 'phoneNumber', value: '', searchParamName: 'mobilePhone', searchFieldValue: '',
        },
      },
      filters: [],
      nameFilterFieldValue: '',
      emailFilterFieldValue: '',
      serviceFilterFieldValue: '',
      phoneNumberFilterFieldValue: '',
    };
  },
  computed: {
    ...mapState({
      pagination: (state) => state.ModuleEdocSign.signatory.pagination,
      signatories: (state) => state.ModuleEdocSign.signatory.signatories,
    }),
    ...mapGetters({
      options: 'ModuleEdocSign/auth/options',
    }),
    selectAll: {
      get() {
        return this.signatories ? this.selectedSignatories.length === this.signatories.length : false;
      },
      set(value) {
        const selectedSignatories = [];

        if (value) {
          this.signatories.forEach((signatory) => {
            selectedSignatories.push(signatory);
          });
        }
        this.selectedSignatories = selectedSignatories;
      },
    },
    showService() {
      if (this.options && this.options.signerCreationFormDefineService) {
        return parseInt(this.options.signerCreationFormDefineService.value);
      }

      return false;
    },
    showPhoneNumber() {
      if (this.options && this.options.signerCreationFormDefineMobilePhone) {
        return parseInt(this.options.signerCreationFormDefineMobilePhone.value);
      }

      return false;
    },
  },
  methods: {
    ...mapActions({
      getSignatories: 'ModuleEdocSign/signatory/getSignatories',
      storeDeleteSignatory: 'ModuleEdocSign/signatory/deleteSignatory',
    }),
    deleteSignatory(sign) {
      this.storeDeleteSignatory({ userId: sign.userId })
        .then(() => {
          this.getSignatories(this.searchParams);
        });
    },
    deleteMultiple(list) {
      const promises = [];
      for (let i = 0; i < list.length; i++) {
        promises.push(this.deleteSignatory({ userId: list[i].userId }));
      }
      Axios.all(promises)
        .then(() => {
          this.getSignatories(this.searchParams);
        });
    },
    deleteSignatories() {
      this.deleteMultiple(this.selectedSignatories);
    },
    toSignatoryEdit(signId) {
      this.$router.push({ path: '/signatories/external/'.concat(signId) });
    },
    onSortClick(orderBy, fieldName) {
      this.searchParams.orders = [{ fieldName, orderBy }];
      this.getSignatories(this.searchParams);
    },
    onClear() {
      this.searchInput = '';
      this.searchParams.orders = [{ fieldName: 'fullname', orderBy: 'asc' }];
      this.searchParams.params = [];
      this.getSignatories(this.searchParams);
    },
    filterOnColumn(filter, filterValue) {
      filter.value = filterValue;
      this.addFilter(filter);
      this.availableFilters.name.searchFieldValue = '';
    },
    addFilter(filterToAdd) {
      this.filters = this.filters.filter((filter) => filter.name !== filterToAdd.name);
      this.filters.push(filterToAdd);
      this.searchOnFilters();
    },
    removeFilter(filterToRemove) {
      this.filters = this.filters.filter((filter) => filter.name !== filterToRemove.name);
      this.searchOnFilters();
    },
    resetFilters() {
      this.filters = [];
      this.searchOnFilters();
    },
    searchOnFilters() {
      this.searchParams.orders = [{ fieldName: 'fullname', orderBy: 'asc' }];
      this.searchParams.params = [];
      this.filters.forEach((filter) => {
        this.searchParams.params.push({
          paramName: filter.searchParamName,
          paramValue: filter.value,
        });
      });
      this.getSignatories(this.searchParams);
    },
    searchByName() {
      this.filters = [];
      this.searchParams.orders = [{ fieldName: 'fullname', orderBy: 'asc' }];
      this.searchParams.params = [{
        paramName: 'token', paramValue: this.searchInput,
      }];
      this.searchParams.offset = 0;
      this.getSignatories(this.searchParams);
    },
    goToPage(page) {
      this.searchParams.offset = (page - 1) * this.searchParams.limit;
      this.getSignatories(this.searchParams);
    },
  },
  mounted() {
    this.getSignatories(this.searchParams);
  },
};
</script>
